import { InteractionConfig } from './types';
import { AnimatePresence, motion } from 'framer-motion';

export default function Interaction(props: {
	shouldPlay: boolean;
	config: InteractionConfig;
	onLoaded: () => void;
	onDone: () => void;
	onError: (e: Error) => void;
}) {
	// call after preloading is done
	props.onLoaded();
	// call onDone when your interaction is done or after some timeout
	setTimeout(() => {
		props.onDone();
	}, props.config.timeout);

	return (
		<AnimatePresence>
			<div className="relative w-full h-full">
				<audio src="/slack.mp3" autoPlay={true} />
				<motion.div

					initial={{ opacity: 0, y: 50 }}
					exit={{ opacity: 0, y: 50 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.2, delay: 0 }}
				>
					<div className="flex items-center space-x-4 bg-gray-600 border-gray-400 rounded-3xl p-4 max-w-full shadow-md break-words">
						{/* <div className=''> Updated to items-center */}
							<img alt="jesus" src='./logo.png' className='h-12 shadow-md'></img>
							<div className='min-w-[400px] max-w-4xl pr-10'>
								<h2 className="text-md font-a font-bold text-white">Tangia</h2>
								<h2 className="text-md font-a font-bold text-white">#Chat</h2>
								<p className="text-left text-sm sm:text-md lg:text-lg font-a text-white text-overflow: hidden; ">
									{props.config.msgFrom}: {props.config.msg}</p>
							</div>
						{/* </div> */}
					</div>
				</motion.div>
			</div>
		</AnimatePresence>
	);
}

// motion.div is a special component provided by the Framer Motion library in React. Framer Motion is a popular library for animations in React.

// A motion.div is essentially a div element that has been enhanced with animation capabilities. You can animate various properties of a motion.div such as opacity, scale, position (x, y), rotation, and more.

// In your code, motion.div is being used to animate the opacity and y-position of the divs. The initial, exit, and animate props are used to specify the starting, ending, and intermediate states of the animation. The transition prop is used to control the duration and delay of the animation.
